import { ThemeWrapper } from 'components/ThemeWrapper'
import { DASHBOARD_PATH } from '../config/config.exporter'
import { Button } from 'ui'

const Home = () => {
  return (
    <ThemeWrapper>
      <div className='w-full h-[100vh] flex flex-col justify-center items-center'>
        <p className='font-bold text-black text-3xl'>404</p>
        <p>Pagina no encontrada</p>
        <p>¿Te perdiste?</p>

        <a className='mt-5' href={DASHBOARD_PATH}>
          <Button>Ir al dashboard</Button>
        </a>
      </div>
    </ThemeWrapper>
  )
}

export default Home
