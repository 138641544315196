import '@fontsource/tangerine/400.css'
import '@fontsource/tangerine/700.css'
import { IInspectionCompanyConfig } from 'interfaces/inspection.interface'
import React from 'react'
import { hexToHsl } from 'ui'

export const ThemeWrapper = (props: {
  children: React.ReactNode
  themeConfig?: IInspectionCompanyConfig
}) => {
  const primaryDefault = '258 90% 66%'
  const secondaryDefault = '210 40% 96.1%'

  return (
    <>
      <style jsx global>
        {`
          :root {
            --primary: ${hexToHsl(props.themeConfig?.config?.primaryColor) ||
            primaryDefault};
            --secondary: ${hexToHsl(
              props.themeConfig?.config?.secondaryColor,
            ) || secondaryDefault};
            --ring: ${hexToHsl(props.themeConfig?.config?.primaryColor) ||
            primaryDefault};
          }
        `}
      </style>
      {props.children}
    </>
  )
}
